import React, { ReactElement } from "react";
import { GeoObject } from "../models/GeoObject.model.ts";
import GeoObjectBadge from "../collectables/GeoObjectBadge.tsx";
import Size from "../collectables/Size.enum.ts";

interface FigurenDetailProps {
    geoObjecten: GeoObject[];
}

const FigurenDetailSheet: React.FC<FigurenDetailProps> = ({ geoObjecten }): ReactElement => {

    const figurenGeoObjectBadges = [];

    geoObjecten.map((geoObject: GeoObject) =>
        figurenGeoObjectBadges
            .push(
                <div className="geo-object-list-item">
                    <GeoObjectBadge object={geoObject} size={Size.Medium}></GeoObjectBadge>
                    <div className="title">{geoObject.title}</div>
                </div>
            )
    );

    return (
        <div className="collected-bottom-sheet geo-object">
            {figurenGeoObjectBadges.length !== 0 && figurenGeoObjectBadges}
            {figurenGeoObjectBadges.length === 0 && 
            <div className="explainer">Gevonden historische personen komen hier te staan.</div>}
        </div>
    );
};

export default FigurenDetailSheet;
