import React, { ReactElement } from "react";
import { useAuthContext } from "../AuthContext.tsx";
import { useModal } from "../modals/ModalContext";
import { User } from "../models/User.model.ts";

interface ProfileProps {
    user: User
}

// TODO gebruikers gegevens hier ophalen ipv in props
const Profile: React.FC<ProfileProps> = ({ user }): ReactElement => {
 
    const { logout } = useAuthContext();
    const { closeModal } = useModal();

    const logOutAndLeave = (): void => {
        closeModal()
        logout()
    }
    return (
        <span className="profile-modal">
            <h1>{user.username}</h1>
            {user.email}
            <div className="button-wrap">
                {/* <button>Wijzig profiel</button> */}
                <button onClick={logOutAndLeave}>Log uit</button>
            </div>
        </span>
    );
};

export default Profile;
