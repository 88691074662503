import React, { ReactElement } from "react";
import GeoObjectDetail from "../modals/GeoObjectDetail.tsx";
import { useModal } from "../modals/ModalContext";
import { GeoObject } from "../models/GeoObject.model";
import Size from "./Size.enum.ts";

interface GeoObjectBadgeProps {
    object: GeoObject;
    size: Size;
}

const GeoObjectBadge: React.FC<GeoObjectBadgeProps> = ({ object, size }): ReactElement => {
    const { openModal } = useModal();

    let path = process.env.PUBLIC_URL;

    const handleOpenModal = () => {
        const modalContent = <GeoObjectDetail object={object}></GeoObjectDetail>;
        openModal(modalContent);
    };

    return (
        <div
            onClick={handleOpenModal}
            className={"geo-object-badge  " + size + " " + object.area.name.toLowerCase() + ""}
        >
            <div className="geo-object-img-container">
                <div className="geo-object-border"></div>
                <img alt={object.title} src={path + "/object-images/" + object.avatarFile + ".webp"} />
            </div>
        </div>
    );
};

export default GeoObjectBadge;
