import axios, { AxiosInstance, AxiosResponse } from "axios";
import { jwtDecode, JwtPayload } from "jwt-decode";

const baseURL: string = process.env.REACT_APP_API_BASE_URL;

const axiosInstance: AxiosInstance = axios.create({
    baseURL,
    timeout: 10000,
});

interface LoginRequest {
    username: string;
    password: string;
}

interface RegisterRequest {
    username: string;
    email: string;
    password: string;
}

const getPayloadofToken = (token: string): JwtPayload => {
    const decoded: JwtPayload = jwtDecode<JwtPayload>(token);
    return decoded;
};

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            // Not authorized
        }
        return Promise.reject(error);
    }
);

const ApiService = {
    getAllGeoObjects: (token: string): Promise<AxiosResponse> => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        };
        return axiosInstance.get("/geo-objects", { headers });
    },
    getAllYears: (token: string): Promise<AxiosResponse> => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        };
        return axiosInstance.get("/year", { headers });
    },
    getAllAreas: (token: string): Promise<AxiosResponse> => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        };
        return axiosInstance.get("/area", { headers });
    },
    getAllSoorten: (token: string): Promise<AxiosResponse> => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        };
        return axiosInstance.get("/soort", { headers });
    },
    getUser: (token: string): Promise<AxiosResponse> => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        };
        const userId = getPayloadofToken(token)["id"];
        const url = `/user/${userId}`;

        return axiosInstance.get(url, { headers });
    },
    getUsers: (token: string): Promise<AxiosResponse> => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        };
        return axiosInstance.get("/user", { headers });
    },
    postGeoObjectFound: (token: string, geoObjectId: number): Promise<AxiosResponse> => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        };
        const userId = getPayloadofToken(token)["id"];
        const url = `/user/${userId}/geo-objects/${geoObjectId}`;

        return axiosInstance.post(url, {}, { headers });
    },
    getGeoObjectsFound: (token: string): Promise<AxiosResponse> => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        };
        const userId = getPayloadofToken(token)["id"];
        const url = `/user/${userId}/geo-objects`;

        return axiosInstance.get(url, { headers });
    },
    getGeoObjectsFoundByArea: (token: string, areaId: number): Promise<AxiosResponse> => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        };
        const userId = getPayloadofToken(token)["id"];
        const url = `/user/${userId}/area/${areaId}/geo-objects`;

        return axiosInstance.get(url, { headers });
    },
    getGeoObjectsFoundByYear: (token: string, yearId: number): Promise<AxiosResponse> => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        };
        const userId = getPayloadofToken(token)["id"];
        const url = `/user/${userId}/year/${yearId}/geo-objects`;

        return axiosInstance.get(url, { headers });
    },
    getGeoObjectsFoundBySoort: (token: string, soortId: number): Promise<AxiosResponse> => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        };
        const userId = getPayloadofToken(token)["id"];
        const url = `/user/${userId}/soort/${soortId}/geo-objects`;

        return axiosInstance.get(url, { headers });
    },
    postLogin: (userData: LoginRequest): Promise<AxiosResponse> => {
        const headers = {
            "Content-Type": "application/json",
        };
        return axiosInstance.post("/login/login", userData, { headers });
    },
    postUser: (registerData: RegisterRequest): Promise<AxiosResponse> => {
        const headers = {
            "Content-Type": "application/json",
        };
        return axiosInstance.post("/user", registerData, { headers });
    },
};

export default ApiService;
