import React, { ReactElement } from "react";
import Size from "./Size.enum";

interface PointsProps {
    points: number;
    size: Size;
}

const PointsBadge: React.FC<PointsProps> = ({ points, size }): ReactElement => {
    return (
        <span>
            <div className={"points-badge " + size + " "}>{points}</div>
        </span>
    );
};

export default PointsBadge;
