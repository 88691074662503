const BadgeWandsculptuur = () => {
    return (
        <div className="wandsculptuur">
            <span>
                Wand<br></br>sculptuur
            </span>
        </div>
    );
};

export default BadgeWandsculptuur;
