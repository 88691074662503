import React, { ReactElement, useEffect, useState } from "react";
import { Year } from "../models/Year.model.ts";
import YearBadge from "../collectables/YearBadge.tsx";
import { useAuthContext } from "../AuthContext.tsx";
import ApiService from "../apiService.tsx";
import Size from "../collectables/Size.enum.ts";

interface YearsDetailProps {
    yearsProp: Year[];
}

const YearsDetailSheet: React.FC<YearsDetailProps> = ({ yearsProp }): ReactElement => {
    const [allYears, setAllYears] = useState<Year[]>([]);

    const { token } = useAuthContext();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await ApiService.getAllYears(token);
                if (response?.data) {
                    setAllYears(response.data);
                } else {
                    console.error("Invalid response format:", response);
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    const yearBadges = [];

    allYears
        .sort((a, b) => parseInt(b.name) - parseInt(a.name))
        .map((year: Year) =>
            yearBadges.push(
                <YearBadge
                    size={Size.Small}
                    locked={!yearsProp.some((yearProp) => yearProp.name === year.name)}
                    name={year.name}
                    id={year.id}
                ></YearBadge>
            )
        );

    return (
        <span>
            {yearsProp.length === 0 && (
                <div className="explainer on-top">Verzamelde jaartallen komen hier te staan.</div>
            )}
            <div className="collected-bottom-sheet year">{yearBadges}</div>
        </span>
    );
};

export default YearsDetailSheet;
