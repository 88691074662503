import AreaDetail from "../modals/AreaDetail.tsx";
import { useModal } from "../modals/ModalContext.js";
import { ReactElement } from "react";
import React from "react";
import BadgeKorrewegwijk from "../svg/area/badgeKorrewegwijk.js";
import BadgePaddepoel from "../svg/area/badgePaddepoel.js";
import BadgeOosterparkwijk from "../svg/area/badgeOosterparkwijk.js";
import BadgeCentrum from "../svg/area/badgeCentrum.js";
import BadgeZernike from "../svg/area/badgeZernike.js";
import BadgeSelwerd from "../svg/area/badgeSelwerd.js";
import BadgeBeijum from "../svg/area/badgeBeijum.js";
import Size from "./Size.enum.ts";

interface AreaBadgeProps {
    id: number;
    name: string;
    description: string;
    size: Size;
    locked: boolean;
}

const AreaBadge: React.FC<AreaBadgeProps> = ({ size, id, name, description, locked }): ReactElement => {
    const { openModal } = useModal();

    const handleOpenModal = () => {
        const modalContent = <AreaDetail areaId={id} name={name} description={description}></AreaDetail>;
        openModal(modalContent);
    };

    // TODO in plaats van SVG hier misschien iets doen met verschillende achtergronden
    // const componentsMap = {
    //     "korrewegwijk & dee hoogte": BadgeKorrewegwijk,
    //     "paddepoel": BadgePaddepoel,
    //     "oosterparkwijk": BadgeOosterparkwijk,
    //     "centrum": BadgeCentrum,
    //     "zernike": BadgeZernike,
    //     "selwerd": BadgeSelwerd,
    //     "beijum": BadgeBeijum
    // };

    // const getComponentByName = (name: string) => {
    //     const componentName = name.toLowerCase();
    //     return componentsMap[componentName]  || BadgeKorrewegwijk;
    // };

    return (
        <span>
            {locked ? (
                <div className={"area-badge locked " + size + " "}>
                    <span>{name}</span>
                </div>
            ) : (
                <div onClick={handleOpenModal} className={"area-badge " + name.toLowerCase() + " " + size + ""}>
                    {/* {React.createElement(getComponentByName(name))} */}
                    <span>{name}</span>
                </div>
            )}
        </span>
    );
};

export default AreaBadge;
