import React, { ReactElement, useState } from "react";
import { useModal } from "../modals/ModalContext.js";
import ApiService from "../apiService.tsx";
import { AxiosError } from "axios";

const RegisterSheet: React.FC = (): ReactElement => {
    const { closeModal } = useModal();

    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [email, setEmail] = useState<string>("");

    interface ValidationErrorMessage {
        message: string;
    }

    const [registerInputError, setRegisterInputError] = useState<boolean>(false);
    const [registerInputErrorObject, setRegisterInputErrorObject] = useState<ValidationErrorMessage[]>(null);
    const [registerSuccessful, setRegisterSuccessful] = useState<boolean>(false);

    const hitEnter = (e): void => {
        if (e.keyCode === 13) {
            handleRegister();
        }
    };
    const resetRegisterInputError = () => {
        setRegisterInputError(false);
    };

    const mapMessage = (message: string): string => {
        if (message.includes("len on username failed")) {
            return "De gebruikersnaam is te kort.";
        } else if (message.includes("isEmail on email failed")) {
            return "Het e-mailadres moet geldig zijn.";
        } else if (message.includes("username must be unique")) {
            return "Dit account lijkt al te bestaan.";
        } else if (message.includes("email must be unique")) {
            return "Dit account lijkt al te bestaan.";
        } else {
            return "Registreren is niet gelukt.";
        }
    };

    const returnMessageByResponse = (errorMessages: ValidationErrorMessage[]): string => {
        if (!errorMessages) {
            return null;
        }
        console.log(errorMessages)
        const mappedMessages: string[] = errorMessages.map((message) => mapMessage(message.message));

        if (mappedMessages.length !== 0) {
            return mappedMessages[0];
        }
    };

    const handleRegister = async () => {
        try {
            const response = await ApiService.postUser({
                username: username.toLowerCase(),
                email: email.toLowerCase(),
                password: password,
            });

            if (response) {
                setRegisterInputError(false);
                if (response.status === 201) {
                    setRegisterSuccessful(true);
                }
            } else {
                console.error("Invalid response format:", response);
            }
        } catch (error) {
            // Error is alles wat buiten de 2xx status range valt
            setRegisterInputError(true);
            if (error instanceof AxiosError) {
                // TODO consistente errors uit backend gaan leveren
                // Niet elke error is van type SequelizeValidationError of SequelizeUniqueConstraintError helaas
                if (error.response.data.message.name === "SequelizeValidationError" || error.response.data.message.name === "SequelizeUniqueConstraintError") {
                    setRegisterInputErrorObject(error.response.data.message.errors);
                } else {
                    setRegisterInputErrorObject(null);
                }
            }
        }
    };

    return (
        <div className="register-dialog">
            {registerSuccessful ? (
                <div className="success-message" onClick={closeModal}>
                    <h1>Gelukt!</h1>
                    <span>Je kan nu inloggen en op pad gaan.</span>
                </div>
            ) : (
                ""
            )}
            {registerInputError ? (
                <span className="error-message">{returnMessageByResponse(registerInputErrorObject)}</span>
            ) : (
                ""
            )}
            <form
                className={registerInputError ? "error-form" : registerSuccessful ? "register-success" : ""}
                onClick={(e) => resetRegisterInputError()}
            >
                <input
                    className="input-chain darker"
                    placeholder="gebruikersnaam"
                    onChange={(e) => setUsername(e.target.value)}
                    type="text"
                    maxLength={25}
                    minLength={3}
                ></input>
                <input
                    className="input-chain darker"
                    placeholder="e-mailadres"
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    onKeyDown={(e) => hitEnter(e)}
                ></input>
                <input
                    className="input-chain darker"
                    placeholder="wachtwoord"
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    onKeyDown={(e) => hitEnter(e)}
                ></input>
                <button type="button" className="call-to-action login" onClick={handleRegister}>
                    Maak account
                </button>
            </form>
        </div>
    );
};

export default RegisterSheet;
