import React, { ReactElement, useEffect, useState } from "react";
import { Soort } from "../models/Soort.model.ts";
import SoortBadge from "../collectables/SoortBadge.tsx";
import { useAuthContext } from "../AuthContext.tsx";
import ApiService from "../apiService.tsx";
import Size from "../collectables/Size.enum.ts";

interface SoortDetailProps {
    soortenProp: Soort[];
}

const SoortenDetailSheet: React.FC<SoortDetailProps> = ({ soortenProp }): ReactElement => {
    const [allSoorten, setAllSoorten] = useState<Soort[]>([]);

    const { token } = useAuthContext();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await ApiService.getAllSoorten(token);
                if (response?.data) {
                    setAllSoorten(response.data);
                } else {
                    console.error("Invalid response format:", response);
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    const soortenBadges = [];

    allSoorten
        .sort((a, b) => parseInt(b.name) - parseInt(a.name))
        .map((soort: Soort) =>
            soortenBadges.push(
                <SoortBadge
                    locked={!soortenProp.some((soortProp) => soortProp.name === soort.name)}
                    id={soort.id}
                    size={Size.Medium}
                    name={soort.name}
                ></SoortBadge>
            )
        );

    const soortenBadgesSorted = soortenBadges.sort((a, b) => a.props.locked - b.props.locked);

    return (
        <span>
            {soortenProp.length === 0 && (
                <div className="explainer on-top">Verzamelde kunstsoorten komen hier te staan.</div>
            )}
            <div className="collected-bottom-sheet soort">{soortenBadgesSorted}</div>
        </span>
    );
};

export default SoortenDetailSheet;
