import React, { ReactElement, useState, useEffect } from "react";
import { GeoObject } from "../models/GeoObject.model.ts";
import GeoObjectBadge from "../collectables/GeoObjectBadge.tsx";
import YearBadge from "../collectables/YearBadge.tsx";
import { useAuthContext } from "../AuthContext.tsx";
import ApiService from "../apiService.tsx";
import Size from "../collectables/Size.enum.ts";

interface YearDetailProps {
    yearId: number;
    name: string;
}

const YearDetail: React.FC<YearDetailProps> = ({ yearId, name }): ReactElement => {
    const [geoObjects, setGeoObjects] = useState<GeoObject[]>([]);

    const { token } = useAuthContext();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await ApiService.getGeoObjectsFoundByYear(token, yearId);
                if (response?.data) {
                    setGeoObjects(response.data);
                } else {
                    console.error("Invalid response format:", response);
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    return (
        <div className="year-detail-modal">
            <YearBadge size={Size.Large} name={name} id={yearId} locked={false}></YearBadge>
            <div className="collectables-grid-wrap">
                <span className="collectables-grid-header">
                    {geoObjects.length} object{geoObjects.length !== 1 && "en"} { " uit het jaar " + name}
                </span>
                <div className="collectables-grid geo-object">
                    {geoObjects.map((geoObject: GeoObject) => (
                        <GeoObjectBadge key={geoObject.title} size={Size.Medium} object={geoObject} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default YearDetail;
