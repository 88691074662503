import React, { createContext, useState, useContext } from "react";
import { useSwipeable } from "react-swipeable";

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalType, setModalType] = useState(null);
    const [modalContent, setModalContent] = useState(null);

    const handlers = useSwipeable({
        onSwipedDown: (eventData) => {
            const scrollY = window.scrollY || document.documentElement.scrollTop;
            if (scrollY === 0) {
                closeModal();
            }
        },
        // ...config,
    });

    const openModal = (content, type) => {
        setModalType(type);
        setModalContent(content);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalContent(null);
    };

    return (
        <ModalContext.Provider value={{ isModalOpen, modalContent, openModal, closeModal, modalType }}>
            {children}
            {isModalOpen && (
                <div {...handlers} className={"modal " + modalType}>
                    <button className="btn-close-modal" onClick={closeModal}>
                        X
                    </button>
                    {modalContent}
                </div>
            )}
        </ModalContext.Provider>
    );
};

export const useModal = () => useContext(ModalContext);
