import YearBadge from "../collectables/YearBadge.tsx";
import AreaBadge from "../collectables/AreaBadge.tsx";
import SoortBadge from "../collectables/SoortBadge.tsx";
import { GeoObject } from "../models/GeoObject.model";
import React, { ReactElement, useEffect } from "react";
import PointsBadge from "../collectables/PointsBadge.tsx";
import Size from "../collectables/Size.enum.ts";
import "leaflet/dist/leaflet.css";

import L from "leaflet";

interface GeoObjectDetailProps {
    object: GeoObject;
}

const GeoObjectDetail: React.FC<GeoObjectDetailProps> = ({ object }): ReactElement => {
    const path = process.env.PUBLIC_URL;

    const objectImage = path + "/object-images/" + object.avatarFile + ".webp";

    useEffect(() => {
        // Create a LeafletMap and do it once
        createLeafletMap();
    }, []);

    const createLeafletMap = () => {
        let map = L.map("map", {
            zoom: 15,
            zoomControl: false,
            touchZoom: false,
            dragging: false,
            doubleClickZoom: false,
            scrollWheelZoom: false,
        }).setView([object.location.latitude, object.location.longitude], 15);

        L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
            maxZoom: 19,
            // TODO add attribution some other way
            // attribution: '&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://www.stamen.com/" target="_blank">Stamen Design</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
            ext: "png",
        }).addTo(map);

        const defaultIcon = L.icon({
            iconUrl: L.Icon.Default.prototype._getIconUrl('icon'),
            iconRetinaUrl: L.Icon.Default.prototype._getIconUrl('iconRetina'),
            shadowUrl: L.Icon.Default.prototype._getIconUrl('shadow'),
            iconSize: [60, 62],
            iconAnchor: [30, 62],
            popupAnchor: [-3, -76]
        });

        L.marker([object.location.latitude, object.location.longitude], {icon: defaultIcon}).addTo(map);
    };

    return (
        <span className="geo-object-detail-modal">
            <div
                style={{
                    backgroundImage: `url(${objectImage})`,
                    backgroundSize: "cover",
                    width: "300px",
                    height: "150px",
                    left: "calc(50% - 150px)",
                    position: "absolute",
                    filter: "blur(100px) contrast(200%)",
                }}
            ></div>
            <div>
                <div className={"geo-object-badge large-size " + object.area.name.toLowerCase() + ""}>
                    <div className="geo-object-img-container">
                        <img alt={object.title} src={objectImage} />
                    </div>
                </div>
            </div>
            <div className="geo-object-badge-info">
                <h2>{object.title}</h2>
                {object.description !== "" ? (
                    <span className="object-description">
                        {object.description}
                        {object.url !== "" && (
                            <a className="object-url" target="_blank" rel="noreferrer" href={object.url}>
                                Lees verder
                            </a>
                        )}
                    </span>
                ) : (
                    <span className="object-description">
                        {object.url !== "" && (
                            <a className="object-url" target="_blank" rel="noreferrer" href={object.url}>
                                Lees over dit object
                            </a>
                        )}
                    </span>
                )}
                <div className="object-badges">
                    <div className="badge-row">
                        <YearBadge
                            size={Size.Medium}
                            name={object.year.name}
                            locked={false}
                            id={object.year.id}
                        ></YearBadge>
                        <SoortBadge
                            size={Size.Medium}
                            locked={false}
                            name={object.soort.name}
                            id={object.soort.id}
                        ></SoortBadge>
                        <PointsBadge size={Size.Medium} points={object.points}></PointsBadge>
                    </div>
                    <div className="area-wrap">
                        <div id="map"></div>
                        <AreaBadge
                            size={Size.Medium}
                            locked={false}
                            id={object.area.id}
                            description={object.area.description}
                            name={object.area.name}
                        ></AreaBadge>
                    </div>
                </div>
            </div>
        </span>
    );
};

export default GeoObjectDetail;
