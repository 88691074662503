import React from "react";
import { ReactElement, useState } from "react";
import ApiService from "../apiService.tsx";
import { useModal } from "../modals/ModalContext";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../AuthContext.tsx";
import RegisterSheet from "../modals/RegisterSheet.tsx";
import { AxiosResponse } from "axios";

const Login: React.FC = (): ReactElement => {
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [loginError, setLoginError] = useState<boolean>(false);

    const { openModal } = useModal();
    const { login } = useAuthContext();

    let navigate = useNavigate();

    const handleRegisterOpenModal = () => {
        const modalContent = <RegisterSheet></RegisterSheet>;
        openModal(modalContent, "bottom-sheet");
    };

    const handleLogin = async () => {
        try {
            const response: AxiosResponse = await ApiService.postLogin({
                username: username.toLowerCase(),
                password: password,
            });
            if (response) {
                setLoginError(false);

                if (response.status === 200) {
                    const token: string = response.headers["authorization"];
                    login(token);
                    navigate("/");
                } else {
                    // TODO Handle login failure
                }
            } else {
                console.error("Invalid response format:", response);
            }
        } catch (error) {
            console.log("Inloggen mislukt: ", error);
            setLoginError(true);
        }
    };

    const resetLoginerror = () => {
        setLoginError(false);
    };

    const hitEnter = (e) => {
        if (e.keyCode === 13) {
            handleLogin();
        }
    };

    return (
        <div className="container">
            <div className="intro-dialog">
                <p>
                    <svg id="logo" viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg">
                        <g id="line-supplement">
                            <polyline points="33.2 33.2 48 24 38.8 38.8" />
                        </g>
                        <g id="line">
                            <circle
                                cx="36"
                                cy="36"
                                r="24"
                                fill="#FFF"
                                stroke="#0c0c0c"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                            />
                            <g className="needle">
                            <polyline
                                fill="#fae613"
                                stroke="#0c0c0c"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                points="33.2 33.2 24 48 38.8 38.8"
                            />
                            <polyline
                                fill="#0c0c0c"
                                stroke="#0c0c0c"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                points="33.2 33.2 48 24 38.8 38.8"
                            />
                            </g>
                            <line
                                x1="36"
                                x2="36"
                                y1="21"
                                y2="16"
                                fill="none"
                                stroke="#0c0c0c"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                            />
                            <line
                                x1="36"
                                x2="36"
                                y1="56"
                                y2="51"
                                fill="none"
                                stroke="#0c0c0c"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                            />
                            <line
                                x1="51"
                                x2="56"
                                y1="36"
                                y2="36"
                                fill="none"
                                stroke="#0c0c0c"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                            />
                            <line
                                x1="16"
                                x2="21"
                                y1="36"
                                y2="36"
                                fill="none"
                                stroke="#0c0c0c"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                            />
                        </g>
                    </svg>
                    Urbaniteer is een <br></br>verzamelspel van objecten die in de stad <br></br>Groningen te vinden
                    zijn.
                    {/* Verken en verzamel wijken en buurten, leer over soorten kunst, standbeelden van historische figuren en wordt de kenner met de meeste punten.  */}
                </p>
            </div>
            <div className="login-dialog">
                <form className={loginError ? "error-form" : ""} onClick={(e) => resetLoginerror()}>
                    <input
                        className="input-chain"
                        placeholder="gebruikersnaam"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        type="text"
                    ></input>
                    <input
                        className="input-chain"
                        placeholder="wachtwoord"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                        onKeyDown={(e) => hitEnter(e)}
                    ></input>
                    <button type="button" className="call-to-action login" onClick={handleLogin}>
                        Login
                    </button>
                    <button type="button" onClick={handleRegisterOpenModal} className="like-a-link register">
                        maak nieuw account
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Login;
