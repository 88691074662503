import React, { useEffect, useRef} from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Landing from "./pages/Landing.tsx";
import Login from "./pages/Login.tsx";
import Overview from "./pages/Overview.tsx";
import { useAuthContext } from "./AuthContext.tsx";
import { useModal } from "./modals/ModalContext.js";


function App() {
    const appRef = useRef(null);
    const { isLoggedIn } = useAuthContext();

    const { isModalOpen, modalType } = useModal();

    useEffect(() => {
        if (appRef.current) {
            const isBottomSheetModal = isModalOpen && modalType === "bottom-sheet";
            appRef.current.style.position = isBottomSheetModal ? "fixed" : "static";
            appRef.current.style.left = isBottomSheetModal ? "50%" : "unset";
            appRef.current.style.transform = isBottomSheetModal ? "translateX(-50%)" : "unset";
          }
    }, [isModalOpen]);

    return (
        <div className="App" ref={appRef}>
            {isLoggedIn ? (
                <Routes>
                    <Route path="*" element={<Landing />} />
                    <Route path="/overview" element={<Overview />} />

                </Routes>
            ) : (
                <Routes>
                    <Route path="*" element={<Login />} />
                    <Route path="/login" element={<Login />} />
                </Routes>
            )}
        </div>
    );
}

export default App;
