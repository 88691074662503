import React, { ReactElement } from "react";
import Scan from "../parts/Scan.tsx";

interface ScanSheetProps {
    triggerObjectFound: () => void;
  }

const ScanSheet: React.FC<ScanSheetProps> = ({triggerObjectFound}): ReactElement => {
    return (
        <div className="collected-bottom-sheet">
            <Scan foundAnObject={triggerObjectFound}></Scan>
        </div>
    );
};

export default ScanSheet;
