import React, { ReactElement, useState, useEffect } from "react";
import GeoObjectBadge from "../collectables/GeoObjectBadge.tsx";
import { GeoObject } from "../models/GeoObject.model.ts";
import { Area } from "../models/Area.model.ts";
import { Year } from "../models/Year.model.ts";
import { Soort } from "../models/Soort.model.ts";
import ApiService from "../apiService.tsx";
import { useAuthContext } from "../AuthContext.tsx";
import YearsDetailSheet from "../modals/YearsDetailSheet.tsx";
import AreasDetailSheet from "../modals/AreasDetailSheet.tsx";
import SoortenDetailSheet from "../modals/SoortenDetailSheet.tsx";
import { useModal } from "../modals/ModalContext";
import CommunitySheet from "../modals/CommunitySheet.tsx";
import ProfileCard from "../parts/ProfileCard.tsx";
import { User } from "../models/User.model.ts";
import ScanSheet from "../modals/ScanSheet.tsx";
import FigurenDetailSheet from "../modals/FigurenDetailSheet.tsx";
import Size from "../collectables/Size.enum.ts";

const Landing: React.FC = (): ReactElement => {
    const { logout, token } = useAuthContext();
    const { openModal } = useModal();

    const [user, setUser] = useState<User>();
    const [geoObjectsFromUser, setGeoObjectsFromUser] = useState<GeoObject[]>([]);
    const [areasFromUser, setAreasFromUser] = useState<Area[]>([]);
    const [yearsFromUser, setYearsFromUser] = useState<Year[]>([]);
    const [soortenFromUser, setSoortenFromUser] = useState<Soort[]>([]);
    const [figurenFromUser, setFigurenFromUser] = useState<GeoObject[]>([]);

    const handleObjectFound = () => {
        fetchData();
        fetchUser();
    }

    const handleYearsDetailOpenModal = () => {
        const modalContent = <YearsDetailSheet yearsProp={yearsFromUser}></YearsDetailSheet>;
        openModal(modalContent, "bottom-sheet");
    };

    const handleAreasDetailOpenModal = () => {
        const modalContent = <AreasDetailSheet areasProp={areasFromUser}></AreasDetailSheet>;
        openModal(modalContent, "bottom-sheet");
    };

    const handleSoortenDetailOpenModal = () => {
        const modalContent = <SoortenDetailSheet soortenProp={soortenFromUser}></SoortenDetailSheet>;
        openModal(modalContent, "bottom-sheet");
    };

    const handleCommunityOpenModal = () => {
        const modalContent = <CommunitySheet></CommunitySheet>;
        openModal(modalContent, "bottom-sheet");
    };

    const handleScanOpenModal = () => {
        const modalContent = <ScanSheet triggerObjectFound={handleObjectFound}></ScanSheet>;
        openModal(modalContent, "bottom-scan-sheet");
    };

    const handleFigurenDetailOpenModal = () => {
        const modalContent = <FigurenDetailSheet geoObjecten={figurenFromUser}></FigurenDetailSheet>;
        openModal(modalContent, "bottom-sheet");
    };

    const fetchData = async () => {
        try {
            const areasSet = new Set();
            const yearsSet = new Set();
            const soortenSet = new Set();
            const figurenArray = [];

            const response = await ApiService.getGeoObjectsFound(token);
            if (response?.data) {
                setGeoObjectsFromUser(response.data);

                response.data.forEach((object) => {
                    areasSet.add(object.area.id);
                    yearsSet.add(object.year.id);
                    soortenSet.add(object.soort.id);
                });

                response.data
                    .filter((object: GeoObject) => object.historicalFigure === true)
                    .forEach((object: GeoObject) => {
                        figurenArray.push(object);
                    });

                const uniqueAreas = Array.from(areasSet).map(
                    (areaId) => response.data.find((obj) => obj.area.id === areaId).area
                );
                const uniqueYears = Array.from(yearsSet).map(
                    (yearId) => response.data.find((obj) => obj.year.id === yearId).year
                );
                const uniqueSoorten = Array.from(soortenSet).map(
                    (soortId) => response.data.find((obj) => obj.soort.id === soortId).soort
                );

                setAreasFromUser(uniqueAreas);
                setYearsFromUser(uniqueYears);
                setSoortenFromUser(uniqueSoorten);
                setFigurenFromUser(figurenArray);
            } else {
                console.error("Invalid response format:", response);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const fetchUser = async () => {
        try {
            const response = await ApiService.getUser(token);
            if (response?.data) {
                setUser(response.data);
            } else {
                console.error("Invalid response format:", response);
                logout();
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                // If API returns unauthorized, then it is time to logout
                logout();
            } else {
                console.log(error);
            }
        }
    };

    useEffect(() => {
        fetchUser();
        fetchData();
    }, []);

    return (
        <div className="container">
            <div className="profile-card">
                <ProfileCard user={user}></ProfileCard>
                <div className="stats-wrap">
                    <ul>
                        <li onClick={handleAreasDetailOpenModal}>
                            <div className="meter area">
                                <span className="number">{areasFromUser.length}</span>
                                <span className="label">wijken</span>
                            </div>
                        </li>
                        <li onClick={handleSoortenDetailOpenModal}>
                            <div className="meter soort">
                                <span className="number">{soortenFromUser.length}</span>
                                <span className="label">soorten</span>
                            </div>
                        </li>
                        <li onClick={handleYearsDetailOpenModal}>
                            <div className="meter year">
                                <span className="number">{yearsFromUser.length}</span>
                                <span className="label">jaren</span>
                            </div>
                        </li>
                        <li onClick={handleFigurenDetailOpenModal}>
                            <div className="meter figure">
                                <span className="number">{figurenFromUser.length}</span>
                                <span className="label">personen</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="collectables-grid-wrap geo-object">
                <hr className="horizontal-rule"></hr>
                {geoObjectsFromUser.length !== 0 ? (
                    <div className="collectables-grid geo-object">
                        {geoObjectsFromUser
                        .sort((a: GeoObject, b: GeoObject) => {
                            return new Date(b.UserGeoObject.createdAt).getTime() - new Date(a.UserGeoObject.createdAt).getTime()
                        })
                        .map((geoObjectFromUser: GeoObject) => (
                            <GeoObjectBadge
                                key={geoObjectFromUser.title}
                                size={Size.Medium}
                                object={geoObjectFromUser}
                            />
                        ))}
                    </div>
                ) : (
                    <div className="explainer">
                        Objecten die je in de stad tegenkomt komen hier te staan. Scan je omgeving om iets te vinden.
                    </div>
                )}
                <hr className="horizontal-rule"></hr>
            </div>
            <div className="bottom-container">
                {/* TODO iets van marquee maken voor activiteit van anderen */}
                {/* <div className="community-wrap-main">
                   <span>Derpie heeft iets gevonden </span>
                </div> */}
                <button className="community" onClick={handleCommunityOpenModal}>
                    community
                </button>
                <button className="scan" onClick={handleScanOpenModal}>
                    Scan
                </button>
            </div>
        </div>
    );
};

export default Landing;
