import React, { ReactElement } from "react";
import { User } from "../models/User.model";
import { useModal } from "../modals/ModalContext";
import Profile from "../modals/Profile.tsx";
import PointsDetail from "../modals/PointsDetail.tsx";
import ProfileAvatar from "../modals/ProfileAvatar.tsx";
import openmoji1F60A from "../svg/avatar/1F60A.openmoji.js";

interface ProfileCardProps {
    user: User;
}

const ProfileCard: React.FC<ProfileCardProps> = ({ user }): ReactElement => {
    const { openModal } = useModal();

    const handleProfileOpenModal = () => {
        const modalContent = <Profile user={user}></Profile>;
        openModal(modalContent, "");
    };

    const handleProfileAvatarOpenModal = () => {
        const modalContent = <ProfileAvatar avatar={user.avatar}></ProfileAvatar>;
        openModal(modalContent, "");
    };

    const handlePointsDetailOpenModal = () => {
        const modalContent = <PointsDetail points={user.totalPoints}></PointsDetail>
        openModal(modalContent);
    }

    return user !== undefined ? (
        <div className="profile">
            {/* <div className="profile-avatar" onClick={handleProfileAvatarOpenModal}>{React.createElement(openmoji1F60A)}</div> */}
            <div className="profile-username" onClick={handleProfileOpenModal}>
                {user.username}
            </div>
            <div className="points-badge small-size" onClick={handlePointsDetailOpenModal}>{user.totalPoints}</div>
        </div>
    ) : (
        <div className="profile">
            <div className="profile-username"></div>
            <div className="points-badge small-size"></div>
        </div>
    );
};

export default ProfileCard;
