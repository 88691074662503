import React, { ReactElement, useEffect, useState } from "react";
import { AxiosResponse } from "axios";

import AreaBadge from "../collectables/AreaBadge.tsx";
import YearBadge from "../collectables/YearBadge.tsx";
import SoortBadge from "../collectables/SoortBadge.tsx";
import PointsBadge from "../collectables/PointsBadge.tsx";
import GeoObjectBadge from "../collectables/GeoObjectBadge.tsx";
import ApiService from "../apiService.tsx";
import { useAuthContext } from "../AuthContext.tsx";

import { GeoObject } from "../models/GeoObject.model.ts";
import Size from "../collectables/Size.enum.ts";

interface GeoObjectDetailProps {
    object: GeoObject;
    foundTrigger: () => void;
}

const FoundAnObject: React.FC<GeoObjectDetailProps> = ({ object, foundTrigger }): ReactElement => {
    const { token } = useAuthContext();

    const [foundEarlier, setFoundEarlier] = useState<boolean>(false);

    useEffect(() => {
        ApiService.getGeoObjectsFound(token)
            .then((response: AxiosResponse) => {
                const foundObjects: GeoObject[] = response.data;
                const isObjectFound = foundObjects.some(
                    (foundObject) => foundObject.UserGeoObject.GeoObjectId === object.id
                );
                if (isObjectFound) {
                    setFoundEarlier(true);
                } else {
                    ApiService.postGeoObjectFound(token, object.id)
                        .then((response: AxiosResponse) => {
                            if (response.status === 200) {
                                foundTrigger();
                            } else {
                                console.log("Gevonden object niet opgeslagen");
                            }
                        })
                        .catch((error) => {
                            console.log("Gevonden object niet opgeslagen: ", error);
                        });
                    setFoundEarlier(false);
                }

                // TODO vermelden of jaar, wijk, kunstsoort
            })
            .catch((error) => {
                console.log("Ophalen van gevonden objecten niet gelukt.");
            });
    }, []);

    return (
        <div className="found-an-object-modal">
            <ul className="found-container">
                <li className="found">
                    <GeoObjectBadge key={object.title} size={Size.Medium} object={object} />
                </li>
                <li className="found">
                    {foundEarlier ? (
                        <span className="info">Je hebt het object met de naam {object.title} al eerder gevonden.</span>
                    ) : (
                        <span className="info">
                            Je hebt een {object.soort.name.toLowerCase()} gevonden met de naam {object.title} uit het
                            jaar {object.year.name}.
                        </span>
                    )}
                </li>
                <li className="found horizontal">
                    <YearBadge
                        size={Size.Medium}
                        name={object.year.name}
                        locked={false}
                        id={object.year.id}
                    ></YearBadge>
                    <SoortBadge
                        size={Size.Medium}
                        locked={false}
                        name={object.soort.name}
                        id={object.soort.id}
                    ></SoortBadge>
                </li>
                <li className="found">
                    <span className="info"></span>
                    <AreaBadge
                        size={Size.Medium}
                        locked={false}
                        id={object.area.id}
                        description={object.area.description}
                        name={object.area.name}
                    ></AreaBadge>
                </li>
                <li className="found">
                    <PointsBadge size={Size.Medium} points={object.points}></PointsBadge>
                </li>
            </ul>
        </div>
    );
};

export default FoundAnObject;
