import React, { ReactElement } from "react";

interface ProgressBarProps {
    count: number;
    total: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ count, total }): ReactElement => {
    return (
        <span>
            {count < 30 ? (
                <div className="progress-bar">
                    <div className="count-bar" style={{ width: count.toString() + "%" }}></div>
                    <div className="non-count-bar">{count.toString() + "%"}</div>
                </div>
            ) : (
                <div className="progress-bar">
                    <div className="count-bar" style={{ width: count.toString() + "%" }}>{count.toString() + "%"}</div>
                </div>
            )}
        </span>
    );
};

export default ProgressBar;
