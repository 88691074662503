import React, { ReactElement } from "react";
import PointsBadge from "../collectables/PointsBadge.tsx";
import Size from "../collectables/Size.enum.ts";

interface PointsDetailProps {
    points: number;
}

const PointsDetail: React.FC<PointsDetailProps> = ({ points }): ReactElement => {

    return (
        <div className="points-detail-modal">
            <div>
            <PointsBadge
                    size={Size.Large}
                    points={points}
                ></PointsBadge>
            </div>
            <div className="explainer">Punten verdien je met objecten verzamelen. Historische personen leveren de meeste punten op.</div>
        </div>
    );
};

export default PointsDetail;
