import React, { ReactElement} from "react";
import { useModal } from "../modals/ModalContext.js";
import YearDetail from "../modals/YearDetail.tsx";
import Size from "./Size.enum.ts";

interface YearBadgeProps {
    id: number;
    name: string;
    size: Size;
    locked: boolean;
}

const YearBadge: React.FC<YearBadgeProps> = ({ size, id, name, locked }): ReactElement => {

    const { openModal } = useModal();

    const handleOpenModal = () => {
        const modalContent = <YearDetail yearId={id} name={name}></YearDetail>;
        openModal(modalContent);
    };

    if(name !== '0') {
        if(!locked) {
            return <span><div onClick={handleOpenModal}  className={"year-badge " + size +""}>{name}</div></span>
        } else {
            return <span><div className={"year-badge locked " + size +""}>{name}</div></span>
        }
    }
}

export default YearBadge;