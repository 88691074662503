import React, { ReactElement, useEffect, useState } from "react";
import FoundAnObject from "../modals/FoundAnObject.tsx";
import { useModal } from "../modals/ModalContext.js";
import "leaflet/dist/leaflet.css";

import L from "leaflet";
import { GeoObject } from "../models/GeoObject.model.ts";
import ApiService from "../apiService.tsx";
import { useAuthContext } from "../AuthContext.tsx";
import ScanError from "./ScanError.tsx";

interface LocationTuple {
    readonly [0]: number;
    readonly [1]: number;
}

interface GeoObjectWithDistance extends GeoObject {
    distance: number;
}

interface ScanProps {
    foundAnObject: () => void;
}

const Scan: React.FC<ScanProps> = ({ foundAnObject }): ReactElement => {
    const { token } = useAuthContext();

    const [currentLocation, setCurrentLocation] = useState<LocationTuple>(null);
    const [geoObjects, setGeoObjects] = useState<GeoObject[]>([]);
    const [enhancedGeoObjects, setEnhancedGeoObjects] = useState<GeoObjectWithDistance[]>([]);
    const [watchLocationActive, setWatchLocationActive] = useState<boolean>(false);
    const [locationResponseError, setLocationResponseError] = useState<GeolocationPositionError>(null);
    const [renderedMap, setRenderedMap] = useState(null);

    const getCurrentLocation = (retry: boolean): void => {
        const options = {
            enableHighAccuracy: true,
            timeout: 2000,
        };

        const retryOptions = {
            ...options,
            enableHighAccuracy: false,
        };

        let watchId: number;

        watchId = navigator.geolocation.watchPosition(
            (position) => {
                setLocationResponseError(null);
                setWatchLocationActive(true);
                let latitude = position.coords.latitude;
                let longitude = position.coords.longitude;
                let accuracy = position.coords.accuracy;
                let altitude = position.coords.altitude;
                let altitudeAccuracy = position.coords.altitudeAccuracy;
                let heading = position.coords.heading;
                let speed = position.coords.speed;

                let positionCoordinates: LocationTuple = [latitude, longitude];
                setCurrentLocation(positionCoordinates);
            },
            (error) => {
                console.error("Error getting geolocation: " + error.message);
                setWatchLocationActive(false);

                if (error.PERMISSION_DENIED) {
                    setLocationResponseError(error); // Permission denied
                } else {
                    if (retry) {
                        navigator.geolocation.clearWatch(watchId);
                        watchId = navigator.geolocation.watchPosition(
                            (position) => {
                                setLocationResponseError(null);
                                setWatchLocationActive(true);
                                const latitude = position.coords.latitude;
                                const longitude = position.coords.longitude;
                                const positionCoordinates: LocationTuple = [latitude, longitude];
                                setCurrentLocation(positionCoordinates);
                            },
                            (error) => {
                                setLocationResponseError(error); // Timeout or unavailable
                                setWatchLocationActive(false);
                                console.error("Error getting geolocation with lower accuracy: " + error.message);
                                navigator.geolocation.clearWatch(watchId);
                            },
                            retry ? retryOptions : options
                        );
                    }
                }
            },
            options
        );
    };

    useEffect(() => {
        // Create a LeafletMap and do it once
        createLeafletMap();
    }, []);

    useEffect(() => {
        getCurrentLocation(true);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await ApiService.getAllGeoObjects(token);
                if (response?.data) {
                    setGeoObjects(response.data);
                } else {
                    console.error("Invalid response format:", response);
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();

        getDistancesFromObjects(currentLocation, geoObjects);
        setOrUpdateLocationMarkerOnMap(currentLocation);
    }, [currentLocation]);

    const { openModal } = useModal();

    const handleOpenModal = (item: GeoObject) => {
        const modalContent = <FoundAnObject foundTrigger={foundAnObject} object={item}></FoundAnObject>;
        openModal(modalContent);
    };

    const getCalculatedDistance = (lat1: number, lon1: number, lat2: number, lon2: number): number => {
        const earthRadius = 6371000; // Radius of the Earth in meters

        // Convert latitude and longitude from degrees to radians
        const toRadians = (angle) => (angle * Math.PI) / 180;
        lat1 = toRadians(lat1);
        lon1 = toRadians(lon1);
        lat2 = toRadians(lat2);
        lon2 = toRadians(lon2);

        // Haversine formula
        const dlat = lat2 - lat1;
        const dlon = lon2 - lon1;
        const a = Math.sin(dlat / 2) ** 2 + Math.cos(lat1) * Math.cos(lat2) * Math.sin(dlon / 2) ** 2;
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = earthRadius * c;

        return Math.round(distance);
    };

    const getDistancesFromObjects = (currentLocation: LocationTuple, geObjects: GeoObject[]) => {
        if (currentLocation) {
            const geoObjectsWithDistance: GeoObjectWithDistance[] = geObjects.map((item: GeoObject) => ({
                ...item,
                distance: getCalculatedDistance(
                    currentLocation[0],
                    currentLocation[1],
                    item.location.latitude,
                    item.location.longitude
                ),
            }));

            setEnhancedGeoObjects(geoObjectsWithDistance);
        }
    };

    const setOrUpdateLocationMarkerOnMap = (currentLocation: LocationTuple) => {
        if (currentLocation && renderedMap !== undefined) {
            renderedMap.setView(currentLocation, 16);

            renderedMap.eachLayer((layer) => {
                if (layer instanceof L.Marker) {
                    renderedMap.removeLayer(layer);
                }
            });

            for (const key in geoObjects) {
                if (geoObjects.hasOwnProperty(key)) {
                    const value = geoObjects[key];

                    const defaultIcon = L.icon({
                        iconUrl: L.Icon.Default.prototype._getIconUrl('icon'),
                        iconRetinaUrl: L.Icon.Default.prototype._getIconUrl('iconRetina'),
                        shadowUrl: L.Icon.Default.prototype._getIconUrl('shadow'),
                        iconSize: [80, 82],
                        iconAnchor: [40, 82],
                        popupAnchor: [-3, -76]
                    });

                    L.marker([value.location.latitude, value.location.longitude], {icon: defaultIcon}).addTo(renderedMap);
                }
            }
        }
    };

    const createLeafletMap = () => {
        if (renderedMap === null) {
            let map = L.map("map", {
                zoom: 16,
                zoomControl: false,
                touchZoom: false,
                dragging: false,
                doubleClickZoom: false,
                scrollWheelZoom: false,
            });

            L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
                maxZoom: 19,
                // TODO add attribution some other way
                // attribution: '&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://www.stamen.com/" target="_blank">Stamen Design</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                ext: "png",
            }).addTo(map);

            setRenderedMap(map);
        }
    };

    return (
        <div className="scan-sheet">
            {watchLocationActive && <div className="watch-location active"></div>}
            {!watchLocationActive && <div className="watch-location nonactive"></div>}
            {locationResponseError && (
                <ScanError error={locationResponseError}></ScanError>
            )}
            <div id="map">
                <div className="map-pointer-middle"></div>
            </div>
            {currentLocation !== null && (
                <div className="map-info">
                    {enhancedGeoObjects
                        .sort((a, b) => a.distance - b.distance)
                        .slice(0, 3)
                        .map((item: GeoObjectWithDistance) => {
                            // if (item.distance < 11000000) {
                            return (
                                <button
                                    key={item.title}
                                    onClick={() => handleOpenModal(item)}
                                    className="nearby-object-btn"
                                >
                                    <span className="not-mystery-object"></span>
                                    {item.title}
                                    <span className="distance-label"> {item.distance} meter</span>
                                </button>
                            );
                        })}
                </div>
            )}
        </div>
    );
};

export default Scan;
