import React, { ReactElement, useEffect, useState } from "react";
import { User } from "../models/User.model.ts";
import { useAuthContext } from "../AuthContext.tsx";
import ApiService from "../apiService.tsx";
import PointsBadge from "../collectables/PointsBadge.tsx";
import Size from "../collectables/Size.enum.ts";

const CommunitySheet: React.FC = (): ReactElement => {
    const [allUsers, setAllUsers] = useState<User[]>([]);

    const { token } = useAuthContext();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await ApiService.getUsers(token);
                if (response?.data) {
                    setAllUsers(response.data);
                } else {
                    console.error("Invalid response format:", response);
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    return (
        <div className="community-sheet">
            <ul>
                {allUsers.map((user: User) => (
                    <li key={user.username}>
                        <span className="username">{user.username}</span>
                        <span className="object-count">{user.totalObjects} object{user.totalObjects !== 1 && ("en")}</span>
                        <PointsBadge points={user.totalPoints} size={Size.Tiny}></PointsBadge>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default CommunitySheet;
