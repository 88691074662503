import React, { ReactElement, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FoundAnObject from "../modals/FoundAnObject.tsx";
import { useModal } from "../modals/ModalContext";
import "leaflet/dist/leaflet.css";

import L from "leaflet";
import { GeoObject } from "../models/GeoObject.model";
import ApiService from "../apiService.tsx";
import { useAuthContext} from "../AuthContext.tsx";

interface LocationTuple {
    readonly [0]: number;
    readonly [1]: number;
}

interface GeoObjectWithDistance extends GeoObject {
    distance: number
}

const Overview: React.FC = (): ReactElement => {

    const { token } = useAuthContext();

    const [currentLocation, setCurrentLocation] = useState<LocationTuple>(null);
    const [geoObjects, setGeoObjects] = useState<GeoObject[]>([]);
    const [enhancedGeoObjects, setEnhancedGeoObjects] = useState<GeoObjectWithDistance[]>([]);
    const [renderedMap, setRenderedMap] = useState(null);

    const getCurrentLocation = (): void => {
        navigator.geolocation.watchPosition(
            (position) => {
                let latitude = position.coords.latitude;
                let longitude = position.coords.longitude;
                let accuracy = position.coords.accuracy;
                let altitude = position.coords.altitude;
                let altitudeAccuracy = position.coords.altitudeAccuracy;
                let heading = position.coords.heading;
                let speed = position.coords.speed;

                let positionCoordinates: LocationTuple = [latitude, longitude];
                setCurrentLocation(positionCoordinates);
            },
            (error) => {
                console.error("Error getting geolocation: " + error.message);
            },
            {
                enableHighAccuracy: true,
                timeout: 10000,
            }
        );
    };

    useEffect(() => {
        // Create a LeafletMap and do it once
        createLeafletMap();
    }, []);

    useEffect(() => {
        getCurrentLocation();
    }, []);

    useEffect(() => {
        // TODO get objects from service

        const fetchData = async () => {
            try {
                const response = await ApiService.getAllGeoObjects(token);
                if (response?.data) {
                    setGeoObjects(response.data);
                } else {
                    console.error("Invalid response format:", response);
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();

        getDistancesFromObjects(currentLocation, geoObjects);
        setOrUpdateLocationMarkerOnMap(currentLocation);
    }, [currentLocation]);

    const { openModal } = useModal();

    const handleOpenModal = (item: GeoObject) => {
        const modalContent = <FoundAnObject object={item}></FoundAnObject>;
        openModal(modalContent);
    };

    const getCalculatedDistance = (lat1: number, lon1: number, lat2: number, lon2: number): number => {
        const earthRadius = 6371000; // Radius of the Earth in meters

        // Convert latitude and longitude from degrees to radians
        const toRadians = (angle) => (angle * Math.PI) / 180;
        lat1 = toRadians(lat1);
        lon1 = toRadians(lon1);
        lat2 = toRadians(lat2);
        lon2 = toRadians(lon2);

        // Haversine formula
        const dlat = lat2 - lat1;
        const dlon = lon2 - lon1;
        const a = Math.sin(dlat / 2) ** 2 + Math.cos(lat1) * Math.cos(lat2) * Math.sin(dlon / 2) ** 2;
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = earthRadius * c;

        return Math.round(distance);
    };

    const getDistancesFromObjects = (currentLocation: LocationTuple, geObjects: GeoObject[]) => {
        if (currentLocation) {
            const geoObjectsWithDistance: GeoObjectWithDistance[] = geObjects.map((item: GeoObject) => ({
                ...item,
                distance: getCalculatedDistance(
                    currentLocation[0],
                    currentLocation[1],
                    item.location.latitude,
                    item.location.longitude
                ),
            }));

            setEnhancedGeoObjects(geoObjectsWithDistance);
        }
    };

    const setOrUpdateLocationMarkerOnMap = (currentLocation: LocationTuple) => {
        if (currentLocation && renderedMap !== undefined) {
            renderedMap.setView(currentLocation, 16);

            renderedMap.eachLayer((layer) => {
                if (layer instanceof L.Marker) {
                    renderedMap.removeLayer(layer);
                }
            });

            for (const key in geoObjects) {
                if (geoObjects.hasOwnProperty(key)) {
                    const value = geoObjects[key];
                    L.marker([value.location.latitude, value.location.longitude]).addTo(renderedMap);
                }
            }

            L.marker(currentLocation).addTo(renderedMap);
        }
    };

    const createLeafletMap = () => {
        if (renderedMap === null) {
            let map = L.map("map", {
                zoom: 6,
                zoomControl: false,
                touchZoom: false,
                dragging: false,
                doubleClickZoom: false,
                scrollWheelZoom: false,
            });

            L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
                maxZoom: 12,
                // TODO add attribution some other way
                // attribution: '&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://www.stamen.com/" target="_blank">Stamen Design</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                ext: "png",
            }).addTo(map);

            setRenderedMap(map);
        }
    };

    return (
        <div className="scan-container">
            <Link to="/">
                <button className="btn-close">sluit</button>
            </Link>
            <div id="map">
                <div className="map-pointer-middle"></div>
            </div>
        </div>
    );
};

export default Overview;
