import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HashRouter } from "react-router-dom";
import { ModalProvider } from "./modals/ModalContext";
import { AuthProvider } from "./AuthContext.tsx";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <HashRouter>
        <AuthProvider>
            <ModalProvider>
                <App />
            </ModalProvider>
        </AuthProvider>
    </HashRouter>
);

reportWebVitals();
