import React, { ReactElement, useState, useEffect } from "react";
import { GeoObject } from "../models/GeoObject.model.ts";
import GeoObjectBadge from "../collectables/GeoObjectBadge.tsx";
import AreaBadge from "../collectables/AreaBadge.tsx";
import { useAuthContext } from "../AuthContext.tsx";
import ApiService from "../apiService.tsx";
import ProgressBar from "../collectables/ProgressBar.tsx";
import Size from "../collectables/Size.enum.ts";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

interface AreaDetailProps {
    areaId: number;
    name: string;
    description: string;
}

const AreaDetail: React.FC<AreaDetailProps> = ({ areaId, name, description }): ReactElement => {
    const [geoObjects, setGeoObjects] = useState<GeoObject[]>([]);

    const { token } = useAuthContext();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await ApiService.getGeoObjectsFoundByArea(token, areaId);
                if (response?.data) {
                    setGeoObjects(response.data);
                } else {
                    console.error("Invalid response format:", response);
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        // Create a LeafletMap and do it once
        createLeafletMap();
    }, [geoObjects]);

    const createLeafletMap = () => {
        if (geoObjects.length !== 0) {
            let map = L.map("map", {
                zoom: 15,
                zoomControl: false,
                touchZoom: false,
                dragging: false,
                doubleClickZoom: false,
                scrollWheelZoom: false,
            });
            // .setView([object.location.latitude, object.location.longitude], 15);

            L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
                maxZoom: 16,
                // TODO add attribution some other way
                // attribution: '&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://www.stamen.com/" target="_blank">Stamen Design</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                ext: "png",
            }).addTo(map);

            const defaultIcon = L.icon({
                iconUrl: L.Icon.Default.prototype._getIconUrl("icon"),
                iconRetinaUrl: L.Icon.Default.prototype._getIconUrl("iconRetina"),
                shadowUrl: L.Icon.Default.prototype._getIconUrl("shadow"),
                iconSize: [40, 42],
                iconAnchor: [20, 42],
                popupAnchor: [-3, -76],
            });

            let bounds = new L.LatLngBounds();

            geoObjects.map((geoObject: GeoObject) => {
                let markerObj = L.marker([geoObject.location.latitude, geoObject.location.longitude], {
                    icon: defaultIcon,
                }).addTo(map);
                bounds.extend(markerObj.getLatLng());
            });

            map.fitBounds(bounds, {
                padding: [50, 50],
            });
        }
    };

    return (
        <div className="area-detail-modal">
            <div>
                <div className="area-wrap">
                    <div id="map"></div>
                </div>
                <AreaBadge size={Size.Large} id={areaId} name={name} description="" locked={false}></AreaBadge>
                <ProgressBar total={Math.floor(Math.random() * 100)} count={geoObjects.length}></ProgressBar>
            </div>
            <span>{description}</span>
            <div className="collectables-grid-wrap">
                <span className="collectables-grid-header">
                    {geoObjects.length} object{geoObjects.length !== 1 && "en"}
                </span>
                <div className="collectables-grid geo-object">
                    {geoObjects.map((geoObject: GeoObject) => (
                        <GeoObjectBadge key={geoObject.title} size={Size.Medium} object={geoObject} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default AreaDetail;
