import React, { ReactElement } from "react";
import SafariLogo from "../svg/safariLogo.js";
import iosSettingsLogo from "../svg/iosSettingsLogo.js";


interface ScanErrorProps {
    error: GeolocationPositionError;
}
const ScanError: React.FC<ScanErrorProps> = ({ error }): ReactElement => {
    return (
        <div className="fallback-info">
            <div className="default-explainer">
                {error.PERMISSION_DENIED ? (
                    <span>Het spel kan nog geen locatie bepalen, omdat daar geen toestemming voor is.</span>
                ) : error.TIMEOUT ? (
                    <span> Het bepalen van je locatie duurt wat lang. Je kan het beter opnieuw proberen.</span>
                ) : error.POSITION_UNAVAILABLE ? (
                    <span>Sorry, je locatie is onbekend. Je kan het opnieuw proberen.</span>
                ) : null}
            </div>
            {error.PERMISSION_DENIED ? (
                <span>
                    <div className="generic-explainer">
                        Geef eerst toestemming aan je browser om je locatie te bepalen.
                    </div>
                    <div className="ios-explainer">
                        Op een iPhone dien je toestemming te geven aan je browser. Dat regel je in Instellingen {">"} Safari.
                        <div className="ios-logo-grid">
                        {React.createElement(iosSettingsLogo)}
                        {React.createElement(SafariLogo)}
                        </div>
                    </div>
                </span>
            ) : null}
        </div>
    );
};

export default ScanError;
