import React, { createContext, useContext, useState, useEffect } from "react";
import { jwtDecode, JwtPayload }  from 'jwt-decode';

interface AuthContextType {
    isLoggedIn: boolean;
    token: string | null;
    login: (receivedToken: string) => void;
    logout: () => void;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

const useAuth = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [token, setToken] = useState(null);

    useEffect(() => {
        const loggedInStatus = localStorage.getItem("isLoggedIn");
        const savedToken = localStorage.getItem("token");
        if (loggedInStatus === "true" && savedToken) {
            
            if(isTokenExpValid()) {
                setIsLoggedIn(true);
                setToken(savedToken);
            } else {
                setIsLoggedIn(false);
                setToken(null);
            }

        } else {
            setIsLoggedIn(false);
            setToken(null);
        }
    }, []);

    const login = (receivedToken) => {
        setIsLoggedIn(true);
        setToken(receivedToken);
        localStorage.setItem("isLoggedIn", "true");
        localStorage.setItem("token", receivedToken);
    };

    const logout = () => {
        setIsLoggedIn(false);
        setToken(null);
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("token");
    };

    // TODO check if token is still valid on server side

    // TODO do token refresh if needed


    const isTokenExpValid = (): boolean => {
        const token = localStorage.getItem('token');

        if (token) {
            const decoded: JwtPayload = jwtDecode<JwtPayload>(token);
            const currentTime = Date.now() / 1000;

            return decoded.exp > currentTime;
        }
        return false;
    };

    return { isLoggedIn, token, login, logout };
};

export const AuthProvider = ({ children }) => {
    const auth = useAuth();

    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => {
    return useContext(AuthContext);
};
