import React, { ReactElement, useEffect, useState } from "react";
import { Area } from "../models/Area.model.ts";
import { useAuthContext } from "../AuthContext.tsx";
import ApiService from "../apiService.tsx";
import AreaBadge from "../collectables/AreaBadge.tsx";
import Size from "../collectables/Size.enum.ts";

interface AreasDetailProps {
    areasProp: Area[];
}

const AreasDetailSheet: React.FC<AreasDetailProps> = ({ areasProp }): ReactElement => {
    const [allAreas, setAllAreas] = useState<Area[]>([]);

    const { token } = useAuthContext();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await ApiService.getAllAreas(token);
                if (response?.data) {
                    setAllAreas(response.data);
                } else {
                    console.error("Invalid response format:", response);
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    const areaBadges = [];

    allAreas.map((area: Area) =>
        areaBadges.push(
            <AreaBadge
                size={Size.Medium}
                id={area.id}
                name={area.name}
                description=""
                locked={!areasProp.some((areaProp) => areaProp.name === area.name)}
            ></AreaBadge>
        )
    );

    const areaBadgesSorted = areaBadges.sort((a, b) => a.props.locked - b.props.locked);

    return (
        <span>
            {areasProp.length === 0 && <div className="explainer on-top">Verzamelde wijken, gebieden, buurten komen hier te staan.</div>}
            <div className="collected-bottom-sheet area">{areaBadgesSorted}</div>{" "}
        </span>
    );
};

export default AreasDetailSheet;
