import React, { ReactElement } from "react";
import { useModal } from "../modals/ModalContext.js";
import BadgeMonument from "../svg/soort/badgeMonument.js";
import BadgeVormgeving from "../svg/soort/badgeVormgeving.js";
import BadgeGlaskunstwerk from "../svg/soort/badgeGlaskunstwerk.js";
import BadgeFontein from "../svg/soort/badgeFontein.js";
import BadgeFotografie from "../svg/soort/badgeFotografie.js";
import BadgeLichtwerk from "../svg/soort/badgeLichtwerk.js";
import BadgeMozaiek from "../svg/soort/badgeMozaiek.js";
import BadgeSpeelobject from "../svg/soort/badgeSpeelobject.js";
import BadgeTableau from "../svg/soort/badgeTableau.js";
import BadgeNieuweMedia from "../svg/soort/badgeNieuweMedia.js";
import BadgeLandArt from "../svg/soort/badgeLandArt.js";
import BadgeGraffiti from "../svg/soort/badgeGraffiti.js";
import BadgeBorstbeeld from "../svg/soort/badgeBorstbeeld.js";
import BadgePlaquette from "../svg/soort/badgePlaquette.js";
import BadgeRelief from "../svg/soort/badgeRelief.js";
import BadgeWandsculptuur from "../svg/soort/badgeWandsculptuur.js";
import BadgeKunstArchitectuur from "../svg/soort/badgeKunstArchitectuur.js";
import BadgeVloerGrond from "../svg/soort/badgeVloerGrond.js";
import BadgeSchilderkunst from "../svg/soort/badgeSchilderkunst.js";
import BadgeGevelsteen from "../svg/soort/badgeGevelsteen.js";
import BadgeVrijstaandeSculptuur from "../svg/soort/badgeVrijstaandeSculptuur.js";
import BadgeAanBouwwerk from "../svg/soort/badgeAanBouwwerk.js";
import BadgeKunstWegdek from "../svg/soort/badgeKunstWegdek.js";
import SoortDetail from "../modals/SoortDetail.tsx";
import Size from "./Size.enum.ts";

interface SoortBadgeProps {
    id: number;
    name: string;
    size: Size;
    locked: boolean;
}

const SoortBadge: React.FC<SoortBadgeProps> = ({ name, id, size, locked }): ReactElement => {
    const { openModal } = useModal();

    const handleOpenModal = () => {
        const modalContent = <SoortDetail soortId={id} name={name}></SoortDetail>;
        openModal(modalContent);
    };

    const componentsMap = {
        "monument": BadgeMonument,
        "vormgeving": BadgeVormgeving,
        "nieuwe media": BadgeNieuweMedia,
        "glaskunstwerk": BadgeGlaskunstwerk,
        "tableau": BadgeTableau,
        "lichtwerk": BadgeLichtwerk,
        "mozaïek": BadgeMozaiek,
        "speelobject": BadgeSpeelobject,
        "fotografie": BadgeFotografie,
        "fontein": BadgeFontein,
        "borstbeeld": BadgeBorstbeeld,
        "plaquette": BadgePlaquette,
        "reliëf": BadgeRelief,
        "wandsculptuur": BadgeWandsculptuur,
        "graffiti": BadgeGraffiti,
        "land-art": BadgeLandArt,
        "vloer of grond": BadgeVloerGrond,
        "kunst en architectuur": BadgeKunstArchitectuur,
        "kunst in/aan bouwwerk": BadgeAanBouwwerk,
        "gevelsteen": BadgeGevelsteen,
        "vrijstaande sculptuur": BadgeVrijstaandeSculptuur,
        "kunst in het wegdek": BadgeKunstWegdek,
        "schilderkunst": BadgeSchilderkunst,
    };

    const getComponentByName = (name: string) => {
        const componentName = name.toLowerCase();
        return componentsMap[componentName];
    };

    return (
        <span>
            {locked ? (
                <div className={"soort-badge " + size + " locked"}>
                    <span></span>
                </div>
            ) : (
                <div onClick={handleOpenModal} className={"soort-badge " + size + ""}>
                    {React.createElement(getComponentByName(name))}
                </div>
            )}
        </span>
    );
};

export default SoortBadge;
